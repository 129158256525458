
import { GenerateXml } from "@/models/entities/generate-xml.interface";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { downloadXmlService } from "@/services/api/generate-xml.service";
import { SnackbarModule } from "@zelando/vuelando";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class DownloadComplete extends Vue {
  @Prop({ type: Object as () => GenerateXml, default: null, required: true })
  public generateXml: GenerateXml;
  private async downloadXml(): Promise<void> {
    overlayModule.showOverlay();
    await downloadXmlService
      .GetDownloadXml(this.generateXml, false)
      .then((x) => {
        if (x.status == 200) {
          const element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/xml;charset=utf-8," + encodeURIComponent(x.data)
          );
          const fileName =
            (!this.generateXml?.hasNewXMLFormat
              ? this.$tc("downloadNewXMLFormat.FileNameOld")
              : this.$tc("downloadNewXMLFormat.FileName")) +
            new Date().toFormat("yyyy_MM_dd_HH_mm");
          element.setAttribute("download", fileName);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
      })
      .catch((e) => {
        if (
          (e.response?.status == 500 || e.response?.status == 400) &&
          e.response.data
        )
          snackbarModule.showSnackbar({
            message: this.$tc(e.response.data),
            type: "error",
            timer: 5000,
          });
      });
  }
  private close() {
    this.$emit("close");
  }
}
